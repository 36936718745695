const tg = window.Telegram.WebApp;
import "./main.css"
import rocket from './rocket.png';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function HomePage() {
    useEffect(() => {
        document.body.style.backgroundColor = '#313B43';
    }, []);
    return (
        <div className="container">
        <div className="header">
            <img src={rocket} alt="Rocket Icon" className="rocket-icon" />
            <h1>Биржа</h1>
            <p>Выберите желаемое действие.</p>
        </div>
        <div className="menu">
            <Link to='/crypto-details?exchangePath=CryptoBotToCryptoBot' className="menu-item" tabIndex={0}>
                <div className="icon exchange"></div>
                <span>Обмен крипты в CryptoBot</span>
                <div className="arrow"></div>
            </Link>
            <Link to='/crypto-details?exchangePath=xRocketToxRocket' className="menu-item" tabIndex={0}>
                <div className="icon trade"></div>
                <span>Обмен крипты в xRocket</span>
                <div className="arrow"></div>
            </Link>
            <Link to='/crypto-details?exchangePath=CryptoBotToxRocket' className="menu-item" tabIndex={0}>
                <div className="icon tradeCBxRocket"></div>
                <span>Обмен с CryptoBot на xRocket</span>
                <div className="arrow"></div>
            </Link>
            <Link to='/crypto-details?exchangePath=xRocketToCryptoBot' className="menu-item" tabIndex={0}>
                <div className="icon tradexRocketCB"></div>
                <span>Обмен с xRocket на CryptoBot</span>
                <div className="arrow"></div>
            </Link>
            {/* <Link to='/crypto-details?exchangePath=CryptoBotToexternalWallet' className="menu-item" tabIndex={0}>
                <div className="icon tradeCBEW"></div>
                <span>Обмен с CryptoBot на внешний кошелек</span>
                <div className="arrow"></div>
            </Link>
            <Link to='/crypto-details?exchangePath=xRocketToexternalWallet' className="menu-item" tabIndex={0}>
                <div className="icon tradexRocketEW"></div>
                <span>Обмен с xRocket на внешний кошелек</span>
                <div className="arrow"></div>
            </Link> */}
        </div>
        </div>
    );
}

export default HomePage;
