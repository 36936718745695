// src/App.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './components/mainPage/mainPage';
import CryptoDetailsPage from './components/CryptoDetailsPage/CryptoDetailsPage';
import ExchangePage from './components/exchangePage/page';
import { useTelegram } from './components/hooks/useTelegram';

const App = () => {
  const {tg, onToggleButton} = useTelegram()

  useEffect( () => {
    tg.ready();
  }, [])

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Главная страница */}
          <Route path="/" element={<HomePage />} />

          {/* Вторая страница с деталями */}
          <Route path="/crypto-details" element={<CryptoDetailsPage />} />
          {/* Вторая страница с деталями */}
          <Route path="/exchange" element={<ExchangePage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
