const tg = window.Telegram.WebApp;

export function useTelegram() {
    // Закрыть приложение
    const onClose = () => {
        tg.close();
    };

    // Переключение кнопки (показать/скрыть)
    const onToggleButton = () => {
        if (tg.MainButton.isVisible) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    };

    // Открыть ссылку в Telegram
    const openTelegramLink = (url) => {
        if (tg) {
            tg.openTelegramLink(url);
        } else {
            console.error('Telegram WebApp SDK не загружен.');
        }
    };

    return {
        tg,
        onClose,
        onToggleButton,
        openTelegramLink, // Добавляем метод для открытия ссылок
        user: tg.initDataUnsafe?.user, // Доступ к данным пользователя
    };
}
