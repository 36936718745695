// src/App.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import "./crypto.css";

const CryptoDetailsPage = () => {
    const [cryptos, setCryptos] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    
    // Get getCryptoID and sendCryptoID from query parameters
    const getCryptoID = queryParams.has('getCryptoID') ? queryParams.get('getCryptoID') : 1;
    const sendCryptoID = queryParams.has('sendCryptoID') ? queryParams.get('sendCryptoID') : NaN;

    useEffect(() => {
        document.body.style.backgroundColor = '#000';
    }, []);

    const exchangePath = queryParams.get('exchangePath');
    const change = (isNaN(sendCryptoID)) ? 'send' : 'get';
    useEffect(() => {
        axios.get('https://api.just-trade.ru/api/cryptos', {
            params: { exchangePath, change } // Передаем exchangePath
        })
            .then(response => {
                setCryptos(response.data.data); // Устанавливаем данные, полученные от сервера
            })
            .catch(error => {
                setError('Error processing path: ' + error.message); // Обрабатываем ошибку
            });
    }, []);

    const currency = queryParams.get('exchangePath');

    return (
        <div className="App">
            <h1 className='Header'>Выберите крипту, в которой будете оплачивать</h1>
            <ul className="crypto-list">
                {cryptos.map(crypto => (
                    <li key={crypto.id}>
                        <Link
                            to={`/exchange?exchangePath=${currency}&sendCryptoID=${isNaN(sendCryptoID) ? crypto.id : sendCryptoID}&getCryptoID=${!isNaN(sendCryptoID) ? crypto.id : getCryptoID}`}
                            className="crypto-link"
                        >
                            <img src={crypto.image_url} alt={crypto.name} width="50" height="50" />
                            <div className="crypto-info">
                                <h2 className={crypto.name}>{crypto.name}</h2>
                                <p>{crypto.description}</p>
                                <span className="network">( CryptoBot )</span>
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CryptoDetailsPage;
